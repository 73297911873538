html,
body {
  scroll-behavior: smooth;
  font-family: "AlbertSans-Variable", sans-serif;
  color: #313131;
}

h1,
.h1 {
  font-size: responsive 50px 102px;
  line-height: responsive 54px 98px;
}

div {
  outline: none !important;
}

h2,
.h2 {
  font-family: "AlbertSans-Light", sans-serif !important;
  font-size: responsive 25px 35px;
  line-height: responsive 25px 35px;
}

h3,
.h3 {
  font-size: responsive 16px 18px;
  line-height: 30px;
}

h4,
.h4 {
  font-size: responsive 22px 37px;
  line-height: responsive 24px 39px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 13px;
}

p,
.p {
  font-size: responsive 18px 18px;
  line-height: responsive 22px 29px;
  font-family: "AlbertSans-Regular", sans-serif;
}

.small-text {
  font-size: responsive 16px 18px;
  line-height: responsive 20px 29px;
  font-family: "AlbertSans-Regular", sans-serif !important;
}

.wrapper {
  padding-right: 140px;
  padding-left: 140px;
  max-width: 1679px;
  margin: auto;
  @media (max-width: 1280px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.headroom--unpinned,
.headroom--pinned,
.headroom {
  .burger-text {
    display: none;
  }
  .mob-logo {
    display: block !important;
  }

  .big-logo {
    display: none !important;
  }

  .nav-button {
    height: 50px !important;
    width: 100px !important;
  }
  padding-top: 20px;
  height: 90px;
  background-color: #ffffffc6;
  .nav-links {
    padding-top: 0;
  }

  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  padding-left: 30px !important;
  padding-right: 30px !important;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  .logo {
    height: 40px !important;
    width: auto !important;
  }
  .book-btn {
    padding: 19px 17px !important;
    @media (max-width: 768px) {
      padding: 15px 13px !important;
    }
  }
  @media (max-width: 1024px) {
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    padding-top: 10px;
    background-color: #ffffff;
    height: 80px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 30px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }
  @media (max-width: 770px) {
    height: 70px !important;
  }
}

a {
  text-decoration: none !important;
  p {
    font-family: "AlbertSans-Variable", sans-serif;
    font-size: responsive 14px 17px;
  }
}

.burger {
  .span-2 {
    width: 28px;
    transition: all 0.3s ease-in-out;
  }
}
.burger:hover {
  .span-2 {
    width: 20px;
    transition: all 0.3s ease-in-out;
  }
}

.headroom--top {
  .burger-text {
    display: grid;
    transition: all ease-in-out 2s;
    @media (max-width: 768px) {
      display: none !important;
    }
  }
  .book-btn {
    @media (max-width: 768px) {
      padding: 17px 15px !important;
    }
    padding: 15px 30px !important;
  }
  .mob-logo {
    display: none !important;
  }
  .big-logo {
    display: block !important;
  }
  .nav-button {
    height: 50px !important;
    width: 127px !important;
  }
  filter: none;
  height: 130px;
  background-color: #26326100;
  box-shadow: none;
  display: grid;
  .logo {
    height: 45px !important;
    width: 100% !important;
  }

  @media (max-width: 1280px) {
    padding-right: 80px !important;
    padding-left: 80px !important;
  }

  @media (max-width: 1024px) {
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    padding-top: 10px;
    height: 90px !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 45px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }

  @media (max-width: 770px) {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  padding-left: 140px !important;
  padding-right: 140px !important;

  .logo {
    @media (max-width: 1024px) {
      font-size: 23px !important;
    }
    font-size: 29px !important;
  }
  li {
    font-size: 15px !important;
  }
}

.hover-underline-dark {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  color: #313131;
  padding-bottom: 2px;
}

.hover-underline-dark:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #313131;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

.hover-underline-dark:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

// hover anim light

.hover-underline-light {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  color: #0185db;
  padding-bottom: 2px;
}

.hover-underline-light:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #0185db;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

.hover-underline-light:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.shadow-top {
  -webkit-box-shadow: 0px -4px 3px rgba(30, 30, 30, 0.1);
  -moz-box-shadow: 0px -4px 3px rgba(30, 30, 30, 0.1);
  box-shadow: 0px -4px 3px rgba(30, 30, 30, 0.1);
}

.flip:hover {
  transition: 0.3s;
  .flip-back {
    transition: 0.3s;
    display: grid;
    justify-items: center;
  }
  .flip-front {
    transition: 0.3s;
    display: none;
  }
}

input {
  outline: none !important;
}

.flip {
  display: grid;
  transition: 0.3s;
  .flip-front {
    transition: 0.3s;
    display: grid;
    justify-items: center;
  }
  .flip-back {
    transition: 0.3s;
    justify-items: center;

    display: none;
  }
}

#load {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
}

.privacy-dots {
  ul {
    margin-top: 20px;
  }
  li {
    list-style: circle !important;
    margin-left: 30px;
  }
}
.privacy-head {
  font-size: 40px !important;
}

.header-top-shadow {
  -webkit-box-shadow: 0px -2px 1px rgba(50, 50, 50, 0.124);
  -moz-box-shadow: 0px -2px 1px rgba(50, 50, 50, 0.124);
  box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.124);
}

.bg-from-black {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(9, 9, 121, 0) 70%,
    rgba(0, 212, 255, 0) 100%
  );
}

#mc_embed_signup {
  background: #fff;
  clear: left;
}
#mc-embedded-subscribe-form input[type="checkbox"] {
  display: inline;
  width: auto;
  margin-right: 10px;
}
#mergeRow-gdpr {
  margin-top: 20px;
}
#mergeRow-gdpr fieldset label {
  font-weight: normal;
}
#mc-embedded-subscribe-form .mc_fieldset {
  border: none;
  min-height: 0px;
  padding-bottom: 0px;
}
.icon-slider {
  .flickity-page-dots {
    top: 10px;
  }
  .flickity-page-dots .dot {
    background-color: #0185db;
  }
  .flickity-page-dots .dot.is-selected {
    background-color: #313131;
  }
}
.staff-text {
  p {
    margin-bottom: 1rem;
  }
}
