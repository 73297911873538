.header-slide-up {
  animation: fadeUP 1s ease-in-out;
  @media (max-width: 1024px) {
    animation: fadeUP 0.9s ease-in-out;
  }
  @media (max-width: 750px) {
    animation: fadeUP 0.7s ease-in-out;
  }
}

.head-fadein {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.nav-slide-up {
  animation: fadeUP 1s ease-in-out;
}

// .slide-down {
//   animation: fadeDOWN 2.5s ease-in-out;
//   @media (max-width: 1024px) {
//     animation: fadeDOWN 3s ease-in-out;
//   }
// }

.header-slide-delay {
  animation-delay: 4s;
  animation: fadeIn 1s ease-in-out;
}

.left-svg {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}

.right-svg {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.center-svg {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}

@keyframes fadeUP {
  from {
    opacity: 0;
    transform: translateY(100vh);
  }
  to {
    opacity: 1;
    transform: translateY(0vh);
  }
}

@keyframes fadeDOWN {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
    transform: translateY(50%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideFade {
  from {
    transform: translateX(-150%);
  }

  to {
    transform: translateX(0%);
  }
}

.nav-fade {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.3s;
  animation-fill-mode: forwards;
}

.mob-nav-fade1 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.mob-nav-fade2 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
}

.mob-nav-fade3 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.mob-nav-fade4 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.mob-nav-fade5 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}

.fade-1 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.fade-2 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.1s;
  animation-fill-mode: forwards;
}

.fade-3 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
}

.fade-4 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.3s;
  animation-fill-mode: forwards;
}

.fade-5 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.4s;
  animation-fill-mode: forwards;
}

.fade-6 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.slide-fade {
  transform: translateX(-150%);
  animation: slideFade 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

.icon-fade {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}
